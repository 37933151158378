import React, { useEffect, useState, useCallback, useRef } from "react";
import { useApi } from "../../../api/useApi";
import {
  rlrFailures,
  dataSourceRowByPK,
  dataSourceRowsByRowKeyAndRule,
  rlrStatus,
  dataSourceRowsAndFailuresByPK,
  rlrFailureDetailsByPK,
} from "../../../api/reportQueries";
import PagedTableDetailPlugin from "../../Table/PagedTableDetailPlugin";
import SortTable from "../../Table/SortTable";
import StyledLink from "../../StyledLink";
import TableButton from "../../Button/TableButton";
import { useParams, useLocation } from "react-router-dom";
import ErrorMessages from "../../Notifications/ErrorMessages";
import { recordLevelReportById } from "../../../api/reportQueries";
import styled from "styled-components";
import SpinningLoader from "../../Loaders/SpinningLoader";
import Button from "../../Button";
import SearchByPK from "./SearchByPK";
import ViewMappingModal from "../../../views/RuleStandardPage/ViewMappingModal";
import Modal from "../../Modal";
import { InLineTitlePin } from "../../Table/elements";
import { formatTime } from "../../../common/formats";
import { Label } from "../../Form/FormControls";
import { FaDatabase } from "react-icons/fa";
import { Link } from "react-router-dom";
import Spinner from "../../Loaders/Spinner";
import { requestRLRExport } from "../../../api/reportMutations";
import { NotificationLoading } from "../../Notification/NotificationLoading";
import Notification from "../../Notification";
import Card from "../../Card";
import { MdClose } from "react-icons/md";
import { Route, NavLink } from "react-router-dom";
import { MdExpandMore, MdExpandLess, MdWarning } from "react-icons/md";
import {
  Popover,
  Typography,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";

const FailedTextLink = styled.div`
  margin-bottom: 0.5em;
  display: block;

  font-family: "Source Sans Pro Semibold";
  cursor: pointer;

  &:hover {
    opacity: 0.5;
  }
  color: #009fd4;
`;

const StyledButtonText = styled.div`
  text-decoration: none;
  transition: all ${(props) => props.theme.transitionSpeed} linear;
  font-family: "Source Sans Pro Semibold";
  color: ${(props) => props.theme.linkPrimary};
  &:visited {
    color: ${(props) => props.theme.linkSecondary};
  }
  &:hover {
    text-decoration: underline;
    color: ${(props) => props.theme.linkPrimary};
  }
  &:active {
    text-decoration: underline;
    color: ${(props) => props.theme.linkSecondary};
  }
  &:disabled {
    cursor: pointer;
    opacity: 0.5;
    color: ${(props) => props.theme.linkPrimary};
  }
`;

const FailedInstanceToolTipContainer = styled.div`
  position: absolute;
  border: 1px solid #ccc;
  padding: 10px;
  margin-top: 14px;
  background-color: white;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);

  /* Triangle at the top */
  &::before {
    content: "";
    position: absolute;
    top: -21px;
    left: 15%;
    transform: translateX(-50%); /* Align center with the tooltip */
    border-width: 10px; /* Adjust size of the triangle */
    border-style: solid;
    border-color: transparent transparent #ccc transparent; /* Top border color is the color of the triangle */
  }
`;

const Tabs = styled.div`
  border-bottom: 1px solid ${(props) => props.theme.surfaceAlt};
  background-color: ${(props) => props.theme.surface};
  position: relative;
  padding: 1.2rem;
`;

const TabLink = styled(NavLink)`
  padding: 1rem;
  border-bottom: 2px solid transparent;
  text-decoration: none;
  opacity: 0.5;
  color: ${(props) => props.theme.onSurface};
  &.active {
    opacity: 1;
    border-bottom: 2px solid ${(props) => props.theme.onSecondarySurface};
  }
  &:hover {
    opacity: 1;
  }
`;

const ColumnFailureRollUp = ({ reportData, pk }) => {
  const [{ loading: isProcessing, errors, data }, fetchFailureDetails] =
    useApi();
  // State to track if the fetch operation has been initiated
  const [isFetchInitiated, setIsFetchInitiated] = useState(false);

  useEffect(() => {
    // Only fetch if reportData.id and pk are available and fetch has not been initiated
    if (reportData?.id && pk && !isFetchInitiated) {
      fetchFailureDetails({
        query: rlrFailureDetailsByPK,
        variables: {
          pk: pk,
          rlrId: reportData?.id,
        },
      });

      // Set the flag to true as the fetch operation is now initiated
      setIsFetchInitiated(true);
    }
  }, [reportData, pk, isFetchInitiated, fetchFailureDetails]);

  const failuresByPK = data?.rlrFailureDetailsByPK?.nodes ?? [];

  const [showConfirm, setShowConfirm] = useState(false);
  const [activeRow, setActiveRow] = useState(null); // State to track the active row

  const handleRowClick = (failure) => {
    setShowConfirm(failure);
    setActiveRow(failure); // Update the active row state
  };

  const failureColumns = [
    {
      Header: "Policy Name",
      accessor: "failure.ruleName",
      Cell: ({ row }) => {
        const policyInstances = reportData?.rules?.find(
          (rule) => rule?.rule?.id === row?.original?.failure?.ruleStandardId
        )?.rule?.instances;

        const currentInstance = policyInstances.find(
          (instance) =>
            instance.latestVersion.id ===
            row?.original?.failure?.ruleInstanceVersionId
        );

        const mappingDetails = {
          sourceId: row?.original?.sourceId,
          instanceVersionId: row?.original?.failure?.ruleInstanceVersionId,
          ruleInstanceId: currentInstance?.id,
          title: row?.original?.failure?.ruleName,
          ruleId: row?.original?.failure?.ruleStandardId,
        };

        return (
          <div>
            <StyledButtonText
              style={{
                cursor: "pointer",
                color: activeRow === row.original.failure ? "black" : "auto",
              }} // Highlight the active row
              list
              onClick={() => handleRowClick(mappingDetails)}
            >
              {row?.original?.failure?.ruleName}
            </StyledButtonText>
          </div>
        );
      },
    },
    {
      Header: "Column Name",
      accessor: "columnName",
      Cell: ({ row }) => {
        const selectedColumnName = row?.original?.columnName?.split(/\|/);
        return (
          <div>
            {selectedColumnName?.length > 1
              ? selectedColumnName[1]
              : row?.original?.columnName}
          </div>
        );
      },
    },
    {
      Header: "Column Value",
      accessor: "columnValue",
    },
    {
      Header: "Source Name",
      accessor: "sourceName",
      Cell: ({ row }) => (
        <div>
          <StyledLink to={`/sources/${row?.original?.sourceId}`}>
            {row?.original?.sourceName}
          </StyledLink>
        </div>
      ),
    },
  ];

  // Use a unique key for the ViewMappingModal that changes when showConfirm changes
  const modalKey = showConfirm
    ? `${showConfirm.sourceId}-${showConfirm.instanceVersionId}-${showConfirm.ruleInstanceId}-${showConfirm.ruleId}`
    : "initial";
  if (errors?.length) return <ErrorMessages errors={errors} />;
  if (!failuresByPK?.length && isProcessing) return "Loading Failed Data";
  if (!isProcessing && !failuresByPK?.length) return "No Failures Found";
  return (
    <>
      <h3>
        Failed Data for Loan ID: {reportData?.groupingKeyAlias}: {pk}
      </h3>
      <div style={{ display: "flex" }}>
        <div style={{ flex: 1 }}>
          <SortTable
            data={failuresByPK}
            columns={failureColumns}
            defaultPageSize={50}
          />
        </div>

        {showConfirm && (
          <div
            style={{
              flex: 1,
              paddingLeft: "1rem",
              borderLeft: "1px solid #ccc",
            }}
          >
            <div
              style={{
                borderLeft: "1px solid #ccc",
                marginLeft: "1rem",
                paddingRight: "1rem",
                width: "1rem",
              }}
            ></div>
            <div
              style={{ position: "absolute", right: 0, marginRight: "1.5rem" }}
            >
              <Button onClick={() => setShowConfirm(false)}>Hide Policy</Button>
            </div>
            <h3 style={{ marginBottom: "2rem" }}>
              Policy: {showConfirm?.title}
            </h3>
            <ViewMappingModal
              key={modalKey} // Add the key here
              sourceId={showConfirm?.sourceId}
              instanceVersionId={showConfirm?.instanceVersionId}
              ruleInstanceId={showConfirm?.ruleInstanceId}
              ruleId={showConfirm?.ruleId}
              editEnabled={true}
            />
          </div>
        )}
      </div>
    </>
  );
};

const FailedInstanceToolTip = ({ children }) => {
  // Tooltip component content here...
  return (
    <FailedInstanceToolTipContainer>{children}</FailedInstanceToolTipContainer>
  );
};

const SourceSummary = ({ reportData, dataStatus }) => {
  const sources = reportData?.sources;

  return (
    <>
      <div style={{ display: "flex" }}>
        <div>
          <h4>Status:</h4>
          {dataStatus?.rlrStatus ? (
            <>
              {dataStatus?.rlrStatus?.inProgress && <p>Report In Progress</p>}
              <p>
                Last Updated:{" "}
                {dataStatus?.rlrStatus?.lastUpdatedTime &&
                  formatTime(new Date(dataStatus?.rlrStatus?.lastUpdatedTime))}
              </p>
            </>
          ) : (
            "Loading Report Status"
          )}

          <h4>Total Failures: </h4>
          <p style={{ color: "red" }}>{dataStatus?.rlrStatus?.totalFailures}</p>

          <h4>Primary Key Common Name</h4>
          <p>{reportData?.groupingKeyAlias}</p>
        </div>

        <div style={{ marginLeft: "auto" }}>
          <h4>Source Report Summary</h4>

          {sources?.map((incSource) => {
            const source = incSource?.source;
            const latestReport = source?.latestReport;
            if (latestReport?.refreshSummary) {
              return (
                <>
                  {latestReport?.timestamp ? (
                    <InLineTitlePin>
                      <div style={{ fontSize: "0.875rem" }}>
                        {formatTime(new Date(latestReport?.timestamp))} -{" "}
                        <StyledLink to={`/sources/${source?.id}`}>
                          {source?.name}
                        </StyledLink>
                      </div>
                    </InLineTitlePin>
                  ) : null}
                </>
              );
            } else {
              return (
                <>
                  <div style={{ fontSize: "0.875rem" }}>
                    No Report Data Available -{" "}
                    <StyledLink to={`/sources/${source?.id}`}>
                      {source?.name}
                    </StyledLink>
                  </div>
                </>
              );
            }
          })}
        </div>
      </div>
    </>
  );
};

const FullHeightContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

function ViewRowFailureData({ id, pk, reportData, details, rowKey }) {
  const [relatedOnly, setRelatedOnly] = useState(true);
  const [showConfirm, setShowConfirm] = useState(false);
  const [{ loading, errors, data }, fetchInfo] = useApi();
  const sources = reportData?.sources;

  useEffect(() => {
    const variables = {
      rlrId: id,
      rowKey: rowKey,
      ruleInstanceVersionId: details?.policyInstanceVersionId,
      onlyFailingColumns: relatedOnly,
    };
    fetchInfo({
      query: dataSourceRowsByRowKeyAndRule,
      variables,
    });
  }, [relatedOnly, fetchInfo, id, rowKey, details?.policyInstanceVersionId]);

  const availableSourceNodes = data?.dataSourceRowsByRowKeyAndRule?.nodes;

  if (loading) return "Loading";
  if (!availableSourceNodes?.length) return "No data available";

  return (
    <div style={{ marginTop: "1rem" }}>
      {showConfirm ? (
        <Modal title={showConfirm?.title} hide={() => setShowConfirm(null)}>
          <ViewMappingModal
            sourceId={showConfirm?.sourceId}
            instanceVersionId={showConfirm?.instanceVersionId}
            ruleInstanceId={showConfirm?.ruleInstanceId}
            ruleId={showConfirm?.ruleId}
            editEnabled={true}
          />
        </Modal>
      ) : null}

      <h3>
        <span style={{ color: "#999", fontSize: "1.5rem" }}>
          {reportData?.groupingKeyAlias}
        </span>
        : {pk}
      </h3>
      <div style={{ display: "flex", alignItems: "center" }}>
        <div>
          <h4>
            <span style={{ color: "#999" }}>Policy Name:</span>{" "}
            {details?.policyName}
          </h4>
          <h4>
            <span style={{ color: "#999" }}>Policy Instance Name:</span>{" "}
            {details?.policyInstanceName}
          </h4>
        </div>
        <div style={{ marginLeft: "auto" }}>
          <Label>
            <input
              type="checkbox"
              checked={relatedOnly}
              onChange={(e) => setRelatedOnly((prev) => !prev)}
            />
            Show Failure Related Columns Only
          </Label>
        </div>
      </div>

      <div
        style={{
          padding: "1rem",
          backgroundColor: "#eaeaea",
          borderTop: "1px solid #ccc",
        }}
      >
        {availableSourceNodes?.map((node) => {
          const sourceName = sources.find(
            (s) => s.sourceId === node?.dataSourceId
          )?.source?.name;

          const transformedData = node.cells.reduce(
            (acc, cell) => ({
              ...acc,
              [cell?.columnName.replace(/\./g, "_")]: {
                value: cell?.value,
                isFailure: cell?.isFailure,
              },
            }),
            {}
          );

          const columnsData = Object.keys(transformedData)
            .filter((key) => !key.includes("_bca_internal_id"))
            .map((key, index) => {
              const headerName = key || ""; // Empty string if key is missing
              const uniqueId = key || `Column_${index}`; // Unique ID based on index if key is missing

              return {
                Header: headerName,
                accessor: `${key}.value` || { value: "N/A" }, // fallback to a default value
                id: uniqueId, // Using uniqueId for the column id
                style: { whiteSpace: "unset" },
                Cell: ({ row: { original } }) => {
                  return (
                    <span
                      style={{
                        color:
                          original[key] && original[key]?.isFailure
                            ? "red"
                            : "inherit",
                      }}
                    >
                      {original[key]?.value}
                    </span>
                  );
                },
              };
            });

          const dataSourceId = node?.dataSourceId;
          const ruleVersionId = details?.instance?.ruleVersionId;
          const ruleInstanceId = details?.instance?.ruleInstanceId;
          const title = details?.instance?.title;
          const failedRuleStandardId = details?.instance?.failedRuleStandardId;

          return (
            <div style={{ marginBottom: "1rem" }}>
              <div
                style={{
                  fontSize: "1.2rem",
                  display: "flex",
                  alignItems: "center",
                  fontWeight: "bold",
                  marginBottom: "1rem",
                }}
              >
                <FaDatabase style={{ marginRight: ".5rem" }} />
                {sourceName}
                <div style={{ marginLeft: "auto" }}>
                  <TableButton
                    style={{ marginLeft: "1rem" }}
                    list
                    onClick={() =>
                      setShowConfirm({
                        sourceId: dataSourceId,
                        instanceVersionId: ruleVersionId,
                        ruleInstanceId: ruleInstanceId,
                        title: title,
                        ruleId: failedRuleStandardId,
                      })
                    }
                  >
                    View Mapping
                  </TableButton>
                </div>
              </div>

              <SortTable
                loading={loading}
                data={[transformedData]}
                columns={columnsData}
                defaultPageSize={50}
              />
            </div>
          );
        })}
      </div>

      {errors && <ErrorMessages errors={errors} />}
    </div>
  );
}

function ViewRowData({ id, pk, reportData }) {
  let location = useLocation();
  // Check if the current path includes "/passing/"
  const isAtPassing = location.pathname.includes("/passing/");

  const [{ loading, errors, data }] = useApi(dataSourceRowByPK, {
    rlrId: id,
    pk: pk,
  });

  const [
    {
      loading: rlrFailureLoading,
      errors: rlrFailureErrors,
      data: rlrFailureData,
    },
    fetchRlr,
  ] = useApi(rlrFailures, {
    id: id,
    pkFilter: pk,
    pageSize: 10,
    page: 1,
    ruleStandardColumns: reportData?.rules?.map((r) => {
      return r?.rule?.id;
    }),
    ruleStandardIdFilter: reportData?.rules?.map((r) => r?.rule?.id),
    sortMostFailuresToLeast: true,
  });

  useEffect(() => {
    if (isAtPassing) {
      fetchRlr({
        query: rlrFailures,
        variables: {
          id: id,
          pkFilter: pk,
          pageSize: 10,
          page: 1,
          ruleStandardColumns: reportData?.rules?.map((r) => {
            return r?.rule?.id;
          }),
          sortMostFailuresToLeast: true,
        },
      });
    } else {
      fetchRlr({
        query: rlrFailures,
        variables: {
          id: id,
          pkFilter: pk,
          pageSize: 10,
          page: 1,
          ruleStandardColumns: reportData?.rules?.map((r) => {
            return r?.rule?.id;
          }),
          ruleStandardIdFilter: reportData?.rules?.map((r) => r?.rule?.id),
          sortMostFailuresToLeast: true,
        },
      });
    }
  }, [isAtPassing]);

  const rulesForPK =
    rlrFailureData?.rlrFailures?.nodes?.find((n) => n.primaryKey === pk)
      ?.rules ?? [];

  // stat total failures

  let count = 0;
  rulesForPK?.forEach((r) => {
    if (r?.mapped) count = count + r.failures.length;
  });

  const rulesWithoutFailures = rulesForPK.filter(
    (r) => r?.mapped && !r?.failures?.length
  );

  const sources = reportData?.sources;
  const availableSourceNodes = data?.dataSourceRowByPK?.nodes;

  if (loading || rlrFailureLoading) return "Loading";

  // Helper function to group data by dataSourceId
  function groupBySourceId(nodes) {
    return nodes.reduce((acc, node) => {
      const key = node.dataSourceId;
      if (!acc[key]) {
        acc[key] = [];
      }
      acc[key].push(node);
      return acc;
    }, {});
  }

  const groupedNodes = groupBySourceId(availableSourceNodes || []);

  // Flatten all the data we have collected to an overview

  let flattenedPassing = [];

  rulesWithoutFailures?.forEach((rwof) => {
    const policyName =
      reportData?.rules?.find((r) => r?.rule?.id === rwof?.ruleStandardId)?.rule
        ?.name ?? "";

    flattenedPassing.push({ policyName });
  });

  const passingColumns = [
    {
      Header: "Policy Name",
      accessor: "policyName",
    },
  ];
  return (
    <>
      <h3>
        {reportData?.groupingKeyAlias}: {pk}
      </h3>
      <Tabs>
        <TabLink exact to={`/dynamicviews/${reportData?.id}/?pk=${pk}`}>
          Failures
        </TabLink>
        <TabLink exact to={`/dynamicviews/${reportData?.id}/passing/?pk=${pk}`}>
          Passing
        </TabLink>
        <TabLink exact to={`/dynamicviews/${reportData?.id}/rowdata/?pk=${pk}`}>
          Row Data By Source
        </TabLink>
        {/* <TabLink to="/issues/stats">Statistics</TabLink> */}
      </Tabs>
      <Route
        exact
        path={`/dynamicviews/:reportId`}
        component={() => {
          return (
            <div style={{ marginTop: "1rem" }}>
              {reportData?.id && (
                <ColumnFailureRollUp reportData={reportData} pk={pk} />
              )}
              {/* {showConfirm ? (
                <Modal
                  title={showConfirm?.title}
                  hide={() => setShowConfirm(null)}
                >
                  <ViewMappingModal
                    sourceId={showConfirm?.sourceId}
                    instanceVersionId={showConfirm?.instanceVersionId}
                    ruleInstanceId={showConfirm?.ruleInstanceId}
                    ruleId={showConfirm?.ruleId}
                    editEnabled={true}
                  />
                </Modal>
              ) : null}

              <h3>
                Failed Data for Loan ID: {reportData?.groupingKeyAlias}: {pk}
              </h3>

              <SortTable
                loading={loading}
                data={flattenedData}
                columns={failureColumns}
                defaultPageSize={50}
              /> */}

              {errors && <ErrorMessages errors={errors} />}
              {rlrFailureErrors && <ErrorMessages errors={rlrFailureErrors} />}
            </div>
          );
        }}
      />
      <Route
        exact
        path={`/dynamicviews/:reportId/passing`}
        component={() => {
          return (
            <div style={{ marginTop: "1rem" }}>
              <h3>
                Passing Policies for {reportData?.groupingKeyAlias}: {pk}
              </h3>

              <SortTable
                loading={loading}
                data={flattenedPassing}
                columns={passingColumns}
                defaultPageSize={50}
              />

              {errors && <ErrorMessages errors={errors} />}
              {rlrFailureErrors && <ErrorMessages errors={rlrFailureErrors} />}
            </div>
          );
        }}
      />
      <Route
        exact
        path={`/dynamicviews/:reportId/rowdata`}
        component={() => {
          return (
            <div style={{ marginTop: "1rem" }}>
              <h3>
                Row Data By Source for {reportData?.groupingKeyAlias}: {pk}
              </h3>
              {Object.entries(groupedNodes).map(([dataSourceId, nodes], i) => {
                // Fetching sourceName using the dataSourceId from grouped data
                const source = sources.find(
                  (s) => String(s.sourceId) === String(dataSourceId)
                );

                const sourceName = source?.source?.name;

                const transformedDataList = nodes.map((node) => {
                  return node.cells.reduce(
                    (acc, cell) => ({
                      ...acc,
                      [cell.columnName]: cell.value,
                    }),
                    {}
                  );
                });

                const columnsData =
                  transformedDataList[0] &&
                  Object.keys(transformedDataList[0]).length
                    ? Object.keys(transformedDataList[0])
                        .filter(
                          (key) =>
                            !key.includes("DataSourceId") &&
                            !key.includes("_bca_internal_id")
                        )
                        .map((key, index) => {
                          const headerName = key || ""; // Empty string if key is missing
                          const uniqueId = key || `Column_${index}`; // Unique ID based on index if key is missing

                          return {
                            Header: headerName,
                            accessor: (d) => d[key],
                            id: uniqueId, // Using uniqueId for the column id
                            style: { whiteSpace: "unset" },
                          };
                        })
                    : [];

                return (
                  <div
                    key={i}
                    style={{
                      padding: "1rem",
                      backgroundColor: i % 2 === 0 ? "#eaeaea" : "#ededed",
                      borderTop: i % 2 === 0 ? "" : "1px solid #ccc",
                    }}
                  >
                    <div
                      style={{
                        fontSize: "1.2rem",
                        display: "flex",
                        alignItems: "center",
                        fontWeight: "bold",
                        marginBottom: "1rem",
                      }}
                    >
                      <FaDatabase style={{ marginRight: ".5rem" }} />
                      {sourceName}
                    </div>
                    <SortTable
                      loading={loading}
                      data={transformedDataList}
                      columns={columnsData}
                      defaultPageSize={50}
                    />
                  </div>
                );
              })}
              {errors && <ErrorMessages errors={errors} />}

              {rlrFailureErrors && <ErrorMessages errors={rlrFailureErrors} />}
            </div>
          );
        }}
      />
    </>
  );
}

// Load existing handler
function DynamicViewInit() {
  //get view id from url
  let params = useParams();
  const viewId = params?.viewId ? Number(params?.viewId) : null;

  const [{ loading, errors, data }, fetchReport] = useApi();

  const reportData = data?.recordLevelReportById;
  useEffect(() => {
    if (viewId) {
      fetchReport({
        query: recordLevelReportById,
        variables: {
          id: viewId,
          // where: { enabledState: { eq: "PUBLISHED" } },
        },
      });
    }
  }, [viewId, fetchReport]);

  if (errors?.length) {
    return <ErrorMessages errors={errors} />;
  }

  if (!reportData || (viewId && loading))
    return (
      <FullHeightContainer>
        <SpinningLoader text="Loading View Configuration" />
      </FullHeightContainer>
    );

  return <DynamicView viewId={viewId} reportData={reportData} />;
}

const DynamicView = ({ viewId, reportData }) => {
  // const [viewByInstance, setViewByInstance] = useState(true);

  let location = useLocation();

  // Extract query parameters
  const urlParams = new URLSearchParams(window.location.search);
  const pk = urlParams.get("pk");
  const pId = urlParams.get("pId");
  const pIVId = urlParams.get("pIVId");
  const rowKey = urlParams.get("rowKey");
  const showFailures = urlParams.get("showFailures");
  const show = urlParams.get("show");
  const policyName = urlParams.get("policyName");
  const instanceName = urlParams.get("instanceName");
  const failedRuleStandardId = urlParams.get("failedRuleStandardId");
  const ruleInstanceId = urlParams.get("ruleInstanceId");
  const ruleVersionId = urlParams.get("ruleVersionId");

  const reportName = reportData?.name;

  const [showFailureDetails, setShowFailureDetails] = useState(null);

  useEffect(() => {
    // If all parameters are present
    if (
      pk &&
      pId &&
      pIVId &&
      rowKey &&
      show &&
      instanceName &&
      failedRuleStandardId &&
      ruleInstanceId &&
      ruleVersionId &&
      policyName
    ) {
      setShowFailureDetails({
        primaryKey: pk,
        rowKey: rowKey,
        // failures: rule?.failures,
        show: show,
        policyId: Number(pId),
        policyName: policyName,
        policyInstanceName: instanceName, // Using ruleName from the failure object
        policyInstanceVersionId: Number(ruleVersionId), // Using ruleInstanceVersionId from the failure object
        instance: {
          title: instanceName,
          ruleVersionId: Number(ruleVersionId),
          ruleInstanceId: Number(ruleInstanceId),
          failedRuleStandardId: Number(failedRuleStandardId),
        }, // Using the failure object itself
      });
    } else if (pk && showFailures) {
      setShowFailureDetails({
        primaryKey: pk,
        showFailures: showFailures,
      });
    } else if (pk) {
      setShowFailureDetails({
        primaryKey: pk,
      });
    } else {
      setShowFailureDetails();
    }
  }, [
    pk,
    pId,
    pIVId,
    rowKey,
    show,
    showFailures,
    instanceName,
    policyName,
    failedRuleStandardId,
    ruleInstanceId,
    ruleVersionId,
    location.search,
  ]);

  const [searchPkName, setSearchPkName] = useState();

  const policyIdSet = new Set();

  const [{ loading, errors, data }, doFetch] = useApi();
  const [{ data: dataStatus }] = useApi(rlrStatus, { rlrId: reportData?.id });
  const [relatedOnly, setRelatedOnly] = useState(true);
  const [{ loading: csvLoading, errors: csvErrors, data: csvData }, createCSV] =
    useApi();
  const [showExport, setShowExport] = useState(false);
  const [viewState, setViewState] = useState({
    Sorting: [{ FailureCount: "desc" }],
  });
  const isSortedAndDesc = viewState?.Sorting?.find(
    (s) => s["FailureCount"] === "desc"
  );
  useEffect(() => {
    //if the report is clean, dont hide all the passing rows
    if (dataStatus?.rlrStatus?.totalFailures === 0) {
      setRelatedOnly(false);
    }
  }, [dataStatus]);
  const resetExport = useCallback(() => {
    setShowExport(false);
  }, [setShowExport]);

  useEffect(() => {
    if (csvData) {
      setShowExport(true);
    }
  }, [csvData, setShowExport]);

  const createCSVReport = useCallback(
    ({ rlrId }) => {
      createCSV({
        query: requestRLRExport,
        variables: {
          rlrId: rlrId,
        },
      });
    },
    [createCSV]
  );

  const policyColumns =
    reportData?.rules
      ?.filter((policy) => {
        const policyId = policy?.rule?.id;
        if (policyIdSet.has(policyId) || policyId === undefined) {
          return false; // skip if ID is undefined or already added
        }
        policyIdSet.add(policyId); // add ID to the set
        return true; // include this policy in the result
      })
      ?.map((policy) => {
        const count = dataStatus?.rlrStatus?.failuresByRuleStandardId?.find(
          (standard) => standard?.ruleStandardId === policy?.rule?.id
        )?.count;
        return {
          Header: () => {
            const [anchorEl, setAnchorEl] = useState(null);

            const handleClick = (event) => {
              setAnchorEl(event.currentTarget);
            };

            const handleClose = () => {
              setAnchorEl(null);
            };

            const open = Boolean(anchorEl);
            const id = open ? "simple-popover" : undefined;

            return (
              <div style={{ display: "flex", alignItems: "center" }}>
                <div>{policy?.rule?.name}</div>
                <div
                  style={{ color: "red", marginLeft: ".3rem" }}
                  title={`${policy?.rule?.name} has ${count} failures`}
                >
                  {count ? `(${count})` : ``}
                </div>
                <div
                  style={{ marginLeft: ".5rem", cursor: "pointer" }}
                  onClick={handleClick}
                >
                  <FaDatabase />
                </div>

                <Popover
                  id={id}
                  open={open}
                  anchorEl={anchorEl}
                  onClose={handleClose}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                  }}
                >
                  <Typography sx={{ p: 1, fontWeight: "bold" }}>
                    Data Sources
                  </Typography>
                  <List>
                    {policy.rule.instances
                      .filter((instance) =>
                        reportData.sources.some(
                          (sourceObj) =>
                            sourceObj.sourceId === instance.dataSource.id
                        )
                      )
                      .map((instance, index) => {
                        const buildURLBase = reportData?.sources.find(
                          (s) => s.sourceId === instance.dataSource.id
                        );
                        const latestReportId =
                          buildURLBase?.source?.latestReport?.refreshSummaryId;
                        const sourceId = buildURLBase?.sourceId;
                        const instanceId = instance?.id;
                        const instanceLatestVersionId =
                          instance?.latestVersion?.id;
                        const standardVersionId =
                          instance?.latestVersion?.standardVersionId;

                        return (
                          <ListItem
                            key={index}
                            component="a"
                            href={`/sources/${sourceId}/reports/${latestReportId}/failures/${instanceId}/${instanceLatestVersionId}/${standardVersionId}`}
                            target="_blank"
                            rel="noreferrer"
                          >
                            <ListItemText primary={instance.dataSource.name} />
                          </ListItem>
                        );
                      })}
                  </List>
                </Popover>
              </div>
            );
          },
          id: policy?.rule?.id,
          Cell: ({ row: { original } }) => {
            // Gather all failures associated with the provided ruleStandardId
            const relatedFailures =
              original?.rules
                .filter((rule) => rule.ruleStandardId === policy?.rule?.id)
                .map((rule) => {
                  return { failures: rule.failures, mapped: rule?.mapped };
                }) || [];

            // Step 2: Map over policy instances to find related failures
            const policyInstances = reportData?.rules?.find(
              (rule) => rule?.rule?.id === policy?.rule?.id
            )?.rule?.instances;
            const tooltipRef = useRef(null);
            const [showLocalData, setShowLocalData] = useState(false);
            // Render the failures

            useEffect(() => {
              const handleClickOutside = (event) => {
                if (
                  tooltipRef.current &&
                  !tooltipRef.current.contains(event.target)
                ) {
                  setShowLocalData(false);
                }
              };

              // Bind the event listener
              document.addEventListener("mousedown", handleClickOutside);
              return () => {
                // Unbind the event listener on clean up
                document.removeEventListener("mousedown", handleClickOutside);
              };
            }, [tooltipRef]);

            return (
              <>
                {relatedFailures.map((rule) => {
                  // Check if the original row has no related failures and mapped is true
                  if (rule?.failures?.length === 0 && rule?.mapped) {
                    return <span style={{ color: "green" }}>Pass</span>;
                  } else if (rule?.mapped) {
                    return (
                      <div>
                        <span
                          style={{ color: "red", cursor: "pointer" }}
                          onClick={() => setShowLocalData((prev) => !prev)}
                        >
                          Failed
                        </span>

                        {showLocalData && (
                          <div ref={tooltipRef}>
                            <FailedInstanceToolTip>
                              <div style={{ display: "flex" }}>
                                <div style={{ marginRight: ".5rem" }}>
                                  Failed Instances:
                                </div>
                                <div
                                  title="Close"
                                  style={{
                                    position: "relative",
                                    right: 0,
                                    display: "block",
                                    marginLeft: "auto",
                                    cursor: "pointer",
                                    padding: ".3rem",
                                  }}
                                  onClick={() =>
                                    setShowLocalData((prev) => !prev)
                                  }
                                >
                                  <MdClose />
                                </div>
                              </div>

                              {rule?.failures?.map((failure, index) => {
                                const currentInstance = policyInstances.find(
                                  (instance) =>
                                    instance.latestVersion.id ===
                                    failure.ruleInstanceVersionId
                                );

                                return (
                                  <div key={index}>
                                    <Link
                                      to={`/dynamicviews/${
                                        reportData?.id
                                      }/?pk=${encodeURIComponent(
                                        original?.primaryKey
                                      )}&rowKey=${encodeURIComponent(
                                        failure?.rowKey
                                      )}&pId=${encodeURIComponent(
                                        policy?.rule?.id
                                      )}&pIVId=${encodeURIComponent(
                                        failure.ruleInstanceVersionId
                                      )}&show=${encodeURIComponent(
                                        "true"
                                      )}&policyName=${encodeURIComponent(
                                        policy?.rule?.name
                                      )}&instanceName=${encodeURIComponent(
                                        currentInstance?.title
                                      )}&failedRuleStandardId=${encodeURIComponent(
                                        currentInstance?.latestVersion
                                          ?.standardVersion?.standard?.id
                                      )}&ruleInstanceId=${encodeURIComponent(
                                        currentInstance?.id
                                      )}&ruleVersionId=${encodeURIComponent(
                                        currentInstance?.latestVersion?.id
                                      )}`}
                                      onClick={() => {
                                        setShowFailureDetails({
                                          primaryKey: original?.primaryKey,
                                          rowKey: failure?.rowKey,
                                          // failures: rule?.failures,
                                          show: true,
                                          policyId: policy?.rule?.id,
                                          policyName: policy?.rule?.name,
                                          policyInstanceName: failure.ruleName, // Using ruleName from the failure object
                                          policyInstanceVersionId:
                                            failure.ruleInstanceVersionId, // Using ruleInstanceVersionId from the failure object
                                          instance: currentInstance, // Using the failure object itself
                                        });
                                        setSearchPkName();
                                      }}
                                    >
                                      <FailedTextLink
                                        title={"View Rule"}
                                        data-testid="dynamic-views-rule-link"
                                        iconBump={false}
                                        type="button"
                                        list={"true"}
                                        style={{
                                          marginRight: "1rem",
                                          color: "red",
                                        }}
                                      >
                                        {failure.ruleName}
                                      </FailedTextLink>
                                    </Link>
                                  </div>
                                );
                              })}
                            </FailedInstanceToolTip>
                          </div>
                        )}
                      </div>
                    );
                  } else {
                    return null;
                  }
                })}
              </>
            );
          },
        };
      }) ?? [];

  //Fetch for Table Paged
  const fetchData = React.useCallback(
    ({ pageSize, pageIndex }) => {
      if (searchPkName) {
        doFetch({
          query: rlrFailures,
          variables: {
            pageSize: pageSize,
            id: viewId,
            page: pageIndex,
            pkFilter: searchPkName,
            ruleStandardColumns: reportData?.rules?.map((r) => {
              return r?.rule?.id;
            }),
            sortMostFailuresToLeast: isSortedAndDesc ? true : false,
          },
        });
      } else if (relatedOnly) {
        doFetch({
          query: rlrFailures,
          variables: {
            pageSize: pageSize,
            id: viewId,
            page: pageIndex,
            ruleStandardColumns: reportData?.rules?.map((r) => {
              return r?.rule?.id;
            }),
            ruleStandardIdFilter: reportData?.rules?.map((r) => {
              return r?.rule?.id;
            }),
            sortMostFailuresToLeast: isSortedAndDesc ? true : false,
          },
        });
      } else {
        doFetch({
          query: rlrFailures,
          variables: {
            pageSize: pageSize,
            id: viewId,
            page: pageIndex,
            ruleStandardColumns: reportData?.rules?.map((r) => {
              return r?.rule?.id;
            }),
            sortMostFailuresToLeast: isSortedAndDesc ? true : false,
          },
        });
      }
    },
    [doFetch, viewId, searchPkName, reportData, relatedOnly, isSortedAndDesc]
  );

  //   const views = data?.allRecordLevelReportConfigurationSummaries?.nodes ?? [];
  //   const totalCount =
  //     data?.allRecordLevelReportConfigurationSummaries?.totalCount;
  //   const pageInfo = data?.allRecordLevelReportConfigurationSummaries?.pageInfo;

  const columnsData = [
    {
      Header: reportData?.groupingKeyAlias,
      id: "primaryKeyName",
      Cell: ({ row: { original } }) => {
        return (
          <div>
            {" "}
            <Link
              to={`/dynamicviews/${reportData?.id}/?pk=${encodeURIComponent(
                original?.primaryKey
              )}`}
              onClick={() => {
                setShowFailureDetails({
                  primaryKey: original?.primaryKey,
                });

                setSearchPkName();
              }}
            >
              <FailedTextLink
                title={"View PK"}
                data-testid="dynamic-views-pk-link"
                iconBump={false}
                type="button"
                list={"true"}
                style={{ marginRight: "1rem" }}
              >
                {original?.primaryKey}
              </FailedTextLink>
            </Link>
          </div>
        );
      },
    },
    {
      Header: () => {
        const isSortedAndAsc = viewState?.Sorting?.find(
          (s) => s["FailureCount"] === "asc"
        );
        const isSortedAndDesc = viewState?.Sorting?.find(
          (s) => s["FailureCount"] === "desc"
        );

        return (
          <div
            style={{ cursor: "pointer" }}
            onClick={() =>
              setViewState((prev) => {
                let failCountFilter;
                if (prev?.Sorting?.find((s) => s["FailureCount"] === "desc")) {
                  failCountFilter = {
                    ...prev,
                    Sorting: [{ FailureCount: "asc" }],
                  };
                } else {
                  failCountFilter = {
                    ...prev,
                    Sorting: [{ FailureCount: "desc" }],
                  };
                }

                return failCountFilter;
              })
            }
          >
            Failure Count {isSortedAndDesc && <MdExpandMore />}
            {isSortedAndAsc && <MdExpandLess />}
          </div>
        );
      },

      id: "failureCount",
      Cell: ({ row: { original } }) => {
        let count = 0;
        original?.rules?.forEach((r) => {
          count = count + r.failures.length;
        });

        return (
          <div>
            {count > 0 ? (
              <Link
                to={`/dynamicviews/${reportData?.id}/?pk=${encodeURIComponent(
                  original?.primaryKey
                )}`}
                onClick={() => {
                  setShowFailureDetails({
                    primaryKey: original?.primaryKey,
                    showFailures: count,
                  });
                  setSearchPkName();
                }}
              >
                <FailedTextLink
                  title={"View Data Failures"}
                  data-testid="dynamic-views-fc-link"
                  iconBump={false}
                  type="button"
                  list={"true"}
                  style={{ marginRight: "1rem" }}
                >
                  {count}
                </FailedTextLink>
              </Link>
            ) : (
              count
            )}
          </div>
        );
      },
    },
    ...policyColumns,
  ];

  const failures = data?.rlrFailures?.nodes ?? [];
  const totalCount = data?.rlrFailures?.pageCount;

  if (showFailureDetails) {
    if (showFailureDetails?.show) {
      return (
        <div style={{ padding: "1rem" }}>
          <div>
            <h3>Report: {reportName}</h3>
          </div>

          <div
            style={{ display: "flex", marginTop: "1rem", marginBottom: "1rem" }}
          >
            <div>
              <Link
                to={`/dynamicviews/${reportData?.id}`}
                onClick={() => {
                  setShowFailureDetails();
                  setSearchPkName();
                }}
              >
                <Button
                  title={"Back to Dynamic Views"}
                  data-testid="dynamic-views-link"
                  iconBump={false}
                  type="button"
                  list={"true"}
                >
                  Back
                </Button>
              </Link>
            </div>
          </div>

          <ViewRowFailureData
            id={reportData?.id}
            pk={showFailureDetails?.primaryKey}
            rowKey={showFailureDetails?.rowKey}
            reportData={reportData}
            details={showFailureDetails}
          />
          {errors && <ErrorMessages errors={errors} />}
        </div>
      );
    } else {
      //showing all
      return (
        <div style={{ padding: "1rem" }}>
          <div>
            <h3>Report: {reportName}</h3>
          </div>
          <div
            style={{ display: "flex", marginTop: "1rem", marginBottom: "1rem" }}
          >
            <div>
              <Link
                to={`/dynamicviews/${reportData?.id}`}
                onClick={() => {
                  setShowFailureDetails();
                  setSearchPkName();
                }}
              >
                <Button
                  title={"back to list"}
                  data-testid="dynamic-views-link"
                  iconBump={false}
                  type="button"
                  list={"true"}
                >
                  Back
                </Button>
              </Link>
            </div>
          </div>

          <ViewRowData
            id={reportData?.id}
            pk={showFailureDetails?.primaryKey}
            reportData={reportData}
          />
          {errors && <ErrorMessages errors={errors} />}
        </div>
      );
    }
  }
  return (
    <div style={{ padding: "1rem" }}>
      <div>
        <h3>Report: {reportName}</h3>
      </div>

      <div style={{ display: "flex", marginTop: "1rem", marginBottom: "1rem" }}>
        <div>
          <Link to={`/dynamicviews`}>
            <Button
              title={"Back to Dynamic Views"}
              data-testid="dynamic-views-link"
              iconBump={false}
              type="button"
              list={"true"}
            >
              Back
            </Button>
          </Link>
        </div>
        <div style={{ marginLeft: "auto" }}>
          {/* <Button
            list="true"
            type="button"
            // disabled={savingPdf}
            // onClick={handleDownloadImage}
          >
            History
          </Button> */}

          {showExport ? (
            <NotificationLoading>
              <Notification
                closeCallBack={resetExport}
                text="Your export is being prepared; an email will be sent to you when the export is ready to download."
              />
            </NotificationLoading>
          ) : null}

          <Button
            type="button"
            title={`Export`}
            list={"true"}
            disabled={csvLoading}
            onClick={() =>
              createCSVReport({
                rlrId: reportData?.id,
              })
            }
          >
            {csvLoading ? <Spinner /> : `Export`}
          </Button>
        </div>
      </div>

      <SourceSummary reportData={reportData} dataStatus={dataStatus} />

      <div style={{ display: "flex", alignItems: "center" }}>
        <div style={{ flex: "1" }}>
          <h3 style={{ marginTop: "1.5rem" }}>
            Policy Failures by Primary Key
          </h3>
        </div>
        {dataStatus?.rlrStatus?.reportIsMissingFailures && (
          <div style={{ marginLeft: "auto" }}>
            <div
              style={{
                padding: ".6rem",
                background: "orange",
                color: "#fff",
                borderRadius: ".5rem",
              }}
            >
              <MdWarning style={{ position: "relative", top: "2px" }} /> Report
              is Missing Failures
            </div>
          </div>
        )}
      </div>

      <div style={{ display: "flex", marginTop: "1rem", marginBottom: "1rem" }}>
        <div>
          <SearchByPK action={(searchTerm) => setSearchPkName(searchTerm)} />
        </div>
        <div style={{ marginLeft: "auto" }}>
          <div style={{ marginRight: "2rem" }}>
            <Label>
              <input
                type="checkbox"
                checked={relatedOnly}
                onChange={(e) => setRelatedOnly((prev) => !prev)}
              />
              Only Show Rows with Failures
            </Label>
          </div>
        </div>
      </div>

      <div>
        <PagedTableDetailPlugin
          fetchData={fetchData}
          pageCount={totalCount}
          loading={loading}
          data={failures}
          columns={columnsData}
        />
      </div>
      {csvErrors ? <ErrorMessages errors={csvErrors} /> : null}
      {errors && <ErrorMessages errors={errors} />}
    </div>
  );
};

const Body = () => {
  return <DynamicViewInit />;
};

// //Actions Section of Widget
// function Actions() {
//   return (
//     <>
//       <ActionWrapper>
//         <StyledLink data-testid="add-dynamicview" to={"/dynamicviews"}>
//           <MdArrowBack />
//         </StyledLink>
//       </ActionWrapper>
//     </>
//   );
// }

const CardWrapper = () => {
  return (
    <Card
      title={"DYNAMIC VIEWS"}
      titleDescription={"CREATE REPORTS TO AGGREGATE EXCEPTIONS ACROSS SOURCES"}
      body={Body}
      actions={() => null}
    />
  );
};

export default CardWrapper;
