import React, { useEffect, useContext, useCallback } from "react";
import {
  failureOpportunities,
  totalFailures,
  rowCount,
  constraintFailuresCount,
  qualityScore,
  scoreHistory,
  ruleFailures,
  failuresHistory,
} from "../../../api/dataSourceQueries";
import { useApi } from "../../../api/useApi";
import Report from "./ReportView";
import DashboardHeader from "../../../components/Dashboard/DashboardHeader";
import { dataSourceReports } from "../../../common/paths";
import styled from "styled-components/macro";
import { Route, NavLink, useParams, Switch } from "react-router-dom";
import FailureDetails from "./FailureDetails";
import SplashLoader from "../../../components/Loaders/SplashLoader";
import { format } from "date-fns";
import StyledLink from "../../../components/StyledLink";
import DataSourceImportance from "../DataSourceImportance";
import IssuesList from "../../IssuesPage/IssuesList";
import Card from "../../../components/Card";
import ManageSource from "../ManageSource";
import DataProfile from "../../../components/Forms/Sources/DataProfile";
import PolicyProfile from "../../../components/Forms/Sources/PolicyProfile";
import { MdKey } from "react-icons/md";
import { canUseBCA } from "../../../common/helpers/auth";
import { AuthContext } from "../../../contexts/AuthContext";

// Navigation Components
const Tabs = styled.div`
  border-bottom: 1px solid ${(props) => props.theme.surfaceAlt};
  background-color: ${(props) => props.theme.surface};
  position: relative;
  padding: 0.7rem;
  margin-bottom: 1rem;
  display: flex;
  align-items: center;
`;

const TabLink = styled(NavLink)`
  font-size: 0.9rem;
  padding: 0.7rem;
  border-bottom: 2px solid transparent;
  text-decoration: none;
  opacity: 0.5;
  display: inline-block;
  color: ${(props) => props.theme.onSurface};
  &.active {
    opacity: 1;
    border-bottom: 2px solid ${(props) => props.theme.onSecondarySurface};
  }
  &:hover {
    opacity: 1;
  }
  text-transform: uppercase;
`;

const TabsLeft = styled.div`
  flex: 1;
`;

const TabsRight = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  font-size: 0.9rem;
  margin-left: auto;
`;

const TimestampWrapper = styled.div`
  font-family: "Source Sans Pro Italic";
`;

const TimestampSeparator = styled.div`
  margin: 0 0.5rem;
  font-size: 1.1rem;
`;

const ImportanceContainer = styled.div`
  display: flex;
  min-height: 3rem;
  align-items: center;
  margin-bottom: 1rem;
`;

const DashboardGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(265px, 1fr));
  grid-auto-rows: dense;
  grid-gap: 1rem;
  margin-bottom: 1rem;
`;

const ReportStatus = styled.div`
  background: rgb(0, 153, 255);
  padding: 0.8rem;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  color: #efefef;
  border-radius: 1rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.16), 0 2px 4px rgba(0, 0, 0, 0.23);
`;

const IssueBody = ({ sourceId }) => {
  return <IssuesList filterSource={sourceId} />;
};

const Dashboard = () => {
  let params = useParams();

  const sourceId = Number(params?.sourceId);
    const reportId = params?.reportId;

  const query = `
  query($id: Int!, $reportId: UUID!) {
     dataSource(id: $id) {
       id
       name
      columns{
        id
        name
        ordinal
      }
      priorityLevel
      qualityImpact
      description
      latestRefreshSummaryStatus
      ruleInstancesCount
      primaryKeyConfigured
      latestReport{
        refreshSummaryId
      }
       reportByIdWithAlert(reportId: $reportId){
         refreshSummaryId
         timestamp
         isGeneratingCachedReport
         refreshSummary {
          resultState
          createdOn
          batchName
        }
        columnProfiles{
          profileResults {
            columnId
            value
            valueSubType
            valueType
          }
        }
        failureDetails{
          dataSourceMetrics {
            totalFailuresCount
            totalSuccessfulRowsCount
            totalHighPriorityFailuresCount
            totalOtherPriorityFailuresCount
          }
          columnEntries {
            columnId
            details {
              failureCount
              percentFailureCount
              ruleStandardInstancedName
              standardColumnName
            }
            failureCount
            failureCountChange
            name
            previouslyTopOrder
          }
          ruleStandardEntries {
            details {
              columnName
              failureCount
              percentFailureCount
              standardColumnName
            }
            failureCount
            failureCountChange
            name
            previouslyTopOrder
            rulesStandardId
          }
        }
         ${failureOpportunities.fragment}
         ${totalFailures.fragment}
         ${rowCount.fragment}
         ${qualityScore.fragment}
         ${constraintFailuresCount.fragment}
         ${scoreHistory.fragment}
         ${ruleFailures.fragment}
         ${failuresHistory.fragment}
       }
     }
 }
`;
  const { user } = useContext(AuthContext);
  const [{ loading, errors, data: apiData }, fetch] = useApi();
  const reportData = apiData?.dataSource?.reportByIdWithAlert ?? null;
  const dataSourceBatchName = reportData?.refreshSummary?.batchName ?? null;
  const dataSourceName = apiData?.dataSource?.name ?? null;
  const latestReportId = apiData?.dataSource?.latestReport?.refreshSummaryId;
  const isGeneratingCachedReport =
    apiData?.dataSource?.reportByIdWithAlert?.isGeneratingCachedReport ?? null;
  const priorityLevel = apiData?.dataSource?.priorityLevel ?? null;
  const description = apiData?.dataSource?.description ?? null;
  const qualityImpact = apiData?.dataSource?.qualityImpact ?? null;

  const timestamp = reportData?.timestamp
    ? format(new Date(reportData?.timestamp).getTime(), "MMM dd, HH:mm")
    : null;

  useEffect(() => {
    if (sourceId && reportId) {
      const variables = {
        id: sourceId,
        reportId: reportId,
      };

      fetch({ query: query, variables });
    }
  }, [sourceId, reportId, fetch, query]);

  const fetchLatestReport = useCallback(() => {
    const variables = {
      id: sourceId,
      reportId: reportId,
    };

    fetch({ query: query, variables });
  }, [query, fetch, sourceId, reportId]);

  if (loading) return <SplashLoader text="Loading Data Source" />;

  //Show Report
  return (
    <div style={{ flex: 1, display: "flex", flexDirection: "column" }}>
      <ImportanceContainer>
        <DashboardHeader
          title={dataSourceName}
          batchName={dataSourceBatchName}
          style={{ flex: 1 }}
        />
        {canUseBCA(user?.email) ? (
          apiData?.dataSource?.primaryKeyConfigured ? (
            <div style={{ marginRight: "1rem" }}>
              <MdKey
                style={{ color: "green" }}
                title={"Has Primary Key Configured"}
              />
            </div>
          ) : (
            <div style={{ marginRight: "1rem" }}>
              <MdKey
                style={{ color: "orange" }}
                title={"Does Not Have Primary Key Configured"}
              />
            </div>
          )
        ) : null}
        <DataSourceImportance
          priorityLevel={priorityLevel}
          qualityImpact={qualityImpact}
          description={description}
          sourceId={sourceId}
        />
      </ImportanceContainer>
      <Tabs>
        <TabsLeft>
          <TabLink
            exact
            to={`/sources/${sourceId}/reports/${reportId}`}
            disabled={!latestReportId}
          >
            Report
          </TabLink>
          <TabLink
            to={`/sources/${sourceId}/reports/${reportId}/failures`}
            disabled={!latestReportId}
          >
            Failure Details
          </TabLink>
          <TabLink
            to={`/sources/${sourceId}/reports/${reportId}/profile`}
            disabled={!latestReportId}
          >
            Data Profile
          </TabLink>
          <TabLink
            to={`/sources/${sourceId}/reports/${reportId}/policyprofile`}
          >
            Policy Profile
          </TabLink>
          <TabLink
            exact
            to={`/sources/${sourceId}/reports/${reportId}/issues`}
            disabled={!latestReportId}
          >
            Issues
          </TabLink>
          <TabLink
            isActive={(_, location) => {
              if (location?.pathname?.indexOf("/manage/") > -1) return true;
            }}
            to={`/sources/${sourceId}/manage/policies`}
          >
            Manage
          </TabLink>
        </TabsLeft>
        <TabsRight>
          {timestamp ? (
            <>
              <TimestampWrapper>
                {`${
                  latestReportId === reportId ? "Current" : "Historical"
                } report from ${timestamp}`}
              </TimestampWrapper>
              <TimestampSeparator>|</TimestampSeparator>
            </>
          ) : null}

          <StyledLink to={dataSourceReports(sourceId)}>View More</StyledLink>
        </TabsRight>
      </Tabs>

      {isGeneratingCachedReport === true && (
        <div style={{ marginBottom: "1rem" }}>
          <ReportStatus>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                textAlign: "center",
                justifyContent: "center",
              }}
            >
              Your report is currently being generated... This may take a few
              minutes.
            </div>
          </ReportStatus>
        </div>
      )}

      <Switch>
        <Route
          path={`/sources/:sourceId/reports/:reportId/manage`}
          component={() => (
            <DashboardGrid>
              <Card
                titleDescription={""}
                body={() => <ManageSource sourceId={sourceId} />}
                headless={true}
              />
            </DashboardGrid>
          )}
        />

        <Route
          path={`/sources/:sourceId/reports/:reportId/issues`}
          component={() => (
            <DashboardGrid>
              <Card
                title={"Issues"}
                titleDescription={""}
                body={() => <IssueBody sourceId={sourceId} />}
                headless={true}
              />
            </DashboardGrid>
          )}
        />

        <Route
          path={`/sources/:sourceId/reports/:reportId/failures`}
          component={() => (
            <FailureDetails
              apiData={apiData}
              errors={errors}
              reportById={true}
              sourceId={sourceId}
              reportId={reportId}
            />
          )}
        />

        <Route
          path={`/sources/:sourceId/reports/:reportId/profile`}
          component={() => (
            <DashboardGrid>
              <Card
                title={"Data Profile"}
                titleDescription={"Data Source Statistics"}
                body={() => (
                  <DataProfile
                    sourceId={sourceId}
                    reportId={reportId}
                    data={apiData}
                  />
                )}
              />
            </DashboardGrid>
          )}
        />

        <Route
          path={`/sources/:sourceId/reports/:reportId/policyprofile`}
          component={() => (
            <DashboardGrid>
              <Card
                title={"Policy Profile"}
                titleDescription={"Data Source Policy Profile"}
                body={() => (
                  <PolicyProfile
                    sourceId={sourceId}
                    reportId={reportId}
                    data={apiData}
                  />
                )}
              />
            </DashboardGrid>
          )}
        />

        <Route
          path={`/sources/:sourceId/reports/:reportId`}
          component={() => (
            <Report
              sourceId={sourceId}
              reportId={reportId}
              reportData={reportData}
              isGeneratingCachedReport={isGeneratingCachedReport}
              loading={loading}
              errors={errors}
              dataSourceName={dataSourceName}
              fetchLatestReport={fetchLatestReport}
            />
          )}
        />
      </Switch>
    </div>
  );
};

export default Dashboard;
