import React, { useEffect, useState, useContext, useCallback } from "react";
import Button from "../../components/Button";
import Notification from "../../components/Notification";
import Spinner from "../../components/Loaders/Spinner";
import styled from "styled-components/macro";
import { useApi } from "../../api/useApi";
import SplashLoader from "../../components/Loaders/SplashLoader";
import ErrorMessages from "../../components/Notifications/ErrorMessages";
import TableButton from "../../components/Button/TableButton";
import { dataSourceStatus } from "../../api/dataSourceQueries";
import { setDataSourceEnabledFlag } from "../../api/dataSourceMutations";
import Modal from "../../components/Modal";
import { MdArchive } from "react-icons/md";
import useDataSourceNotifications from "../../Hooks/useDataSourceNotifications";
import { AuthContext } from "../../contexts/AuthContext";
import {
  startDataSourceIngress,
  endRefreshSummary,
} from "../../api/dataSourceMutations";
import { ToastContainer, toast } from "react-toastify";
import { createCsvReportFailureFile } from "../../api/dataSourceMutations";
import DataSourceRunp2 from "../DataSourcesPage/DataSourceRunp2";
import { canUseBCA } from "../../common/helpers/auth";

const TableLoading = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 3;
  display: flex;
  background: rgba(255, 255, 255, 0.7);
`;

const useIsRunning = (original) => {
  const [isRunning, setIsRunning] = useState(
      original?.latestRefreshSummaryStatus === "NONE" ||
      original?.latestRefreshSummaryStatus === "REFERENCE_REPORT_QUEUED"
      ? true
      : false
  );

  const { lastDataSourceNotification } = useDataSourceNotifications();

  useEffect(() => {
    const payload = lastDataSourceNotification?.payload;
    if (
      payload?.AlertType === 11 &&
      payload?.SourceId === original?.id &&
      payload?.Status === 2
    ) {
      setIsRunning(true);
    } else if (
      payload?.AlertType === 11 &&
      payload?.SourceId === original?.id &&
      payload?.Status === 1
    ) {
      setIsRunning(false);
    }
  }, [original, lastDataSourceNotification, setIsRunning]);

  return isRunning;
};

const DataSourceActions = ({ sourceId }) => {
  const [{ loading, errors, data: apiData }] = useApi(dataSourceStatus, {
    id: Number(sourceId),
  });

  const [{ loading: removalLoading, data: removalData }, remove] = useApi();

  const [showConfirm, setShowConfirm] = useState(false);
  const [sourceToDelete, setSourceToDelete] = useState(null);
  const [isEnabled, setIsEnabled] = useState(null);
  const [processLog, setProcessLog] = useState();
  const [processCancelLog, setProcessCancelLog] = useState();
  const [showExport, setShowExport] = useState(false);

  useEffect(() => {
    if (apiData?.dataSource) {
      const enabled = apiData?.dataSource?.enabled;
      setIsEnabled(enabled);
    }
  }, [apiData, setIsEnabled]);

  //handle remove rule update
  useEffect(() => {
    if (removalData) {
      //   fetchData({ pageSize: 10, currentCursor: null });
      setShowConfirm(false);
      setSourceToDelete(null);
      setIsEnabled(removalData?.setDataSourceEnabledFlag?.enabled);
    }
  }, [removalData]);

  useEffect(() => {
    if (sourceToDelete) {
      setShowConfirm(true);
    } else {
      setShowConfirm(false);
    }
  }, [sourceToDelete]);

  const toggleSourceEnabled = ({ id, enabled }) => {
    remove({
      query: setDataSourceEnabledFlag,
      variables: { id: id, enabled: !enabled },
    });
  };

  const [
    {
      errors: cancelRefreshSummaryErrors,
      // loading: loadingCancelRefreshSummaryData,
      data: cancelRefreshSummaryData,
    },
    cancelRefreshSummaryApi,
  ] = useApi();

  const cancelRefreshSummary = useCallback(
    (id) => {
      cancelRefreshSummaryApi({
        query: endRefreshSummary,
        variables: { dataSourceId: id },
      });
    },
    [cancelRefreshSummaryApi]
  );

  const [
    {
      errors: startIngressErrors,
      // loading: startLoadingIngressData,
      data: startIngressData,
    },
    startIngressProcess,
  ] = useApi();

  const startIngress = useCallback(
    (id) => {
      startIngressProcess({
        query: startDataSourceIngress,
        variables: { dataSourceId: id },
      });
    },
    [startIngressProcess]
  );

  const isRunning = useIsRunning(apiData?.dataSource);
  const { user } = useContext(AuthContext);

  const latestRefreshSummaryStatus = apiData?.dataSource?.latestRefreshSummaryStatus;
  const ruleInstancesCount = apiData?.dataSource?.ruleInstancesCount;
  const latestReportId = apiData?.dataSource?.latestReport?.refreshSummaryId;

  const isDisabled =
    isRunning ||
    (latestRefreshSummaryStatus === "NONE" && ruleInstancesCount) ||
    !ruleInstancesCount;

  useEffect(() => {
    if (startIngressData && !startIngressErrors) {
      if (startIngressData?.startDataSourceIngress?.id !== processLog)
        toast.success("Report Started", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      setProcessLog(startIngressData?.startDataSourceIngress?.id);
    }
  }, [startIngressErrors, startIngressData, setProcessLog, processLog]);

  useEffect(() => {
    if (cancelRefreshSummaryData && !cancelRefreshSummaryErrors) {
      if (cancelRefreshSummaryData?.endRefreshSummary?.id !== processCancelLog)
        toast.success("Report Cancelled", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      setProcessCancelLog(cancelRefreshSummaryData?.endRefreshSummary?.id);
    }
  }, [
    cancelRefreshSummaryErrors,
    cancelRefreshSummaryData,
    setProcessCancelLog,
    processCancelLog,
  ]);

  const [{ loading: csvLoading, errors: csvErrors, data: csvData }, createCSV] =
    useApi();

  const resetExport = useCallback(() => {
    setShowExport(false);
  }, [setShowExport]);

  useEffect(() => {
    if (csvData) {
      setShowExport(true);
    }
  }, [csvData, setShowExport]);

  const createCSVReport = useCallback(
    ({ failureType, dataSourceRefreshSummaryId, ruleInstanceVersionId }) => {
      createCSV({
        query: createCsvReportFailureFile,
        variables: {
          dataSourceRefreshSummaryId: dataSourceRefreshSummaryId,
          failureType: failureType,
          ruleInstanceVersionId: ruleInstanceVersionId,
        },
      });
    },
    [createCSV]
  );

  if (loading) return <SplashLoader text={"Loading Status"} />;
  if (errors) return <ErrorMessages errors={errors} />;

  return (
    <>
      {showExport ? (
        <TableLoading>
          <Notification
            closeCallBack={resetExport}
            text="Your data extract is being prepared; an email will be sent to you when the extract is ready to download."
          />
        </TableLoading>
      ) : null}

      {showConfirm ? (
        <Modal
          title={`Confirm Source ${
            sourceToDelete?.enabled ? "Archival" : "Reactivation"
          }`}
          hide={() => setSourceToDelete(null)}
        >
          <p>
            Are you sure you wish to{" "}
            {sourceToDelete?.enabled ? "archive" : "reactivate"} this source?
          </p>
          <div>
            <Button
              type="button"
              list="true"
              disabled={removalLoading}
              danger
              onClick={() => toggleSourceEnabled(sourceToDelete)}
            >
              {removalLoading ? <Spinner /> : "Yes"}
            </Button>
            <Button
              type="button"
              disabled={removalLoading}
              onClick={() => {
                setSourceToDelete(null);
              }}
            >
              Cancel
            </Button>
          </div>
        </Modal>
      ) : null}
      {user && user.role >= 2 && (
        <TableButton
          danger={isEnabled}
          type="button"
          list={true}
          id={`toggleSourceArchiveReactivate`}
          data-testid={`toggleSourceArchiveReactivate`}
          title="Archive Toggle"
          bumpdown={true}
          onClick={() =>
            setSourceToDelete({
              id: sourceId,
              enabled: isEnabled,
            })
          }
        >
          {isEnabled ? (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                fontSize: "0.8125rem",
              }}
            >
              <MdArchive />{" "}
              <span style={{ marginLeft: ".5rem", fontSize: "0.8125rem" }}>
                Archive
              </span>
            </div>
          ) : (
            "Reactivate"
          )}
        </TableButton>
      )}
      {isRunning && user && user.role >= 1 ? (
        <TableButton
          list="true"
          type="button"
          danger={true}
          onClick={() => cancelRefreshSummary(Number(sourceId))}
        >
          Cancel Report
        </TableButton>
      ) : isEnabled ? (
        <TableButton
          list="true"
          type="button"
          onClick={() => {
            if (!isDisabled) startIngress(Number(sourceId));
          }}
          disabled={isDisabled}
        >
          Run Report
        </TableButton>
      ) : null}

      <h3>Data Exports</h3>

      <Button
        type="button"
        title="Export Current Data CSV"
        list={"true"}
        disabled={csvLoading}
        onClick={() =>
          createCSVReport({
            failureType: "CLONE_DATA_SOURCE",
            dataSourceRefreshSummaryId: latestReportId,
            ruleInstanceVersionId: null,
          })
        }
      >
        {csvLoading ? <Spinner /> : "Export Current Data CSV"}
      </Button>
      {csvErrors ? <ErrorMessages errors={csvErrors} /> : null}

      {canUseBCA(user?.email) && (
        <DataSourceRunp2 sourceId={Number(sourceId)} />
      )}
      <ToastContainer />
    </>
  );
};

export default DataSourceActions;
