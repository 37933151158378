import React, { useReducer, useState, useEffect, useCallback } from "react";
import {
  FormControl,
  StyledSelect,
  StyledInput,
  Label,
} from "../../Form/FormControls";
import Button from "../../Button";
// import Spinner from "../../Loaders/Spinner";
import reducer from "./CreateReducer";
import { format } from "date-fns";
import { v4 as uuid } from "uuid";
import Modal from "../../Modal";
import { createWorkflow, updateWorkflow } from "../../../api/workflowMutations";
import { useApi } from "../../../api/useApi";
import { sortByAlpha } from "../../../common/helpers/util";
import { useHistory } from "react-router-dom";
import RenderSegment from "./CreateForm/RenderSegment";
import { WorkflowDetails } from "./CreateForm/CreateFormStyles";
import EntryPoint from "./CreateForm/EntryPoint";
import UpdateThreshold from "./CreateForm/UpdateThreshold";
import AddPipelineForm from "./CreateForm/AddPipelineForm";
import SplashLoader from "../../Loaders/SplashLoader";
import ShowSuccessModal from "./CreateForm/ShowSuccessModal";
import ErrorMessages from "../../../components/Notifications/ErrorMessages";
import { getAllDataSources } from "../../../api/dataSourceQueries";
import { getAllServicerTransferFeeds } from "../../../api/serviceTransferQueries";
import Secondary from "../../Button/Secondary";
import SelectTags from "../../Tags/SelectTags";
import UpdateTags from "../../Tags/UpdateTags";
import ActionSelector from "./CreateForm/Actions/ActionsSelector";
import { useParams } from "react-router-dom";

// ADD SEARCH FIELD TO HIGHLIGHT ETL OR DATASOURCE PIPELINE
const minuteOptions = [
  { value: "0", label: ":00" },
  { value: "1", label: ":15" },
  { value: "2", label: ":30" },
  { value: "3", label: ":45" },
];

const ampmOptions = [
  { value: true, label: "AM" },
  { value: false, label: "PM" },
];

const hourOptions = [
  { value: "1", label: "1" },
  { value: "2", label: "2" },
  { value: "3", label: "3" },
  { value: "4", label: "4" },
  { value: "5", label: "5" },
  { value: "6", label: "6" },
  { value: "7", label: "7" },
  { value: "8", label: "8" },
  { value: "9", label: "9" },
  { value: "10", label: "10" },
  { value: "11", label: "11" },
  { value: "12", label: "12" },
];

const getZone = format(new Date(), "XXX", {
  timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
});

//loop through, give unique id
//replace dependency array ids, with UNique id references
function buildPipelines(dependencies, stateSources, stateTransformations) {
  const uniqueIdArray = dependencies.map((dependent) => {
    return { ...dependent, uid: uuid() };
  });

  // Collect all dependency composite keys from all types of dependencies
  const allDependencyKeys = new Set();
  uniqueIdArray.forEach((dep) => {
    dep.pipelineDependencies.forEach((d) =>
      allDependencyKeys.add(`ETL_PIPELINE-${d.id}`)
    );
    dep.dataSourceDependencies.forEach((d) =>
      allDependencyKeys.add(`DATA_SOURCE-${d.id}`)
    );
    dep.transformationDependencies.forEach((d) =>
      allDependencyKeys.add(`TRANSFORMATION-${d.id}`)
    );
  });

  const newDependencies = uniqueIdArray.map((dependent) => {
    // Convert these IDs to the index of the segment so we can manage and assign
    // Swaps the ETL ID for the UUID of the dependent
    const newDependentPipelines = dependent.pipelineDependencies.map((dp) => {
      return {
        name: dp?.name,
        etlProviderInstanceId: dp?.etlProviderInstanceId,
      };
    });

    const newDependentDataSources = dependent.dataSourceDependencies.map(
      (dd) => {
        const foundDataSource = stateSources.find((ds) => ds.value === dd.id);
        return dd.id;
      }
    );

    const newDependentTransformations =
      dependent.transformationDependencies.map((dd) => {
        const foundTransformation = stateTransformations.find(
          (ds) => ds.value === dd.id
        );

        return dd?.id;
      });

    // Determine if the current dependent is a termination node
    const dependentKey = `${dependent.type}-${dependent.id}`;
    const isTermination = !allDependencyKeys.has(dependentKey);

    return {
      ...dependent,
      // Keep name as a string
      name: dependent?.name,
      pipelineDependencies: newDependentPipelines,
      dataSourceDependencies: newDependentDataSources,
      transformationDependencies: newDependentTransformations,
      isTermination: isTermination,
      ...(dependent?.type === "DATA_SOURCE" && {
        dataQualityThreshold: Number(dependent?.dataSourceDataQualityThreshold),
      }),
    };
  });

  return newDependencies;
}

function flattenDepObjects(dependencies) {
  const cleanDeps = dependencies.map((dep, i) => {
    return {
      name: dep?.name,
      type: dep?.type,
      ...(dep?.type !== "ETL_PIPELINE" && {
        id: dep?.id,
      }),
      ...(dep?.type === "ETL_PIPELINE" && {
        etlProviderInstanceId: dep?.etlProviderInstanceId,
        id: dep?.etlProviderInstanceId,
      }),
      ...(dep?.type === "DATA_SOURCE" && {
        dataSourceDataQualityThreshold: Number(dep?.dataQualityThreshold),
      }),
      dataSourceDependencies: dep.dataSourceDependencies.map((dsDep) => {
        return {
          id: dsDep,
        };
      }),
      pipelineDependencies: dep.pipelineDependencies.map((dsDep) => {
        return {
          name: dsDep?.name,
          etlProviderInstanceId: dsDep?.etlProviderInstanceId,
        };
      }),
      transformationDependencies: dep.transformationDependencies.map(
        (dsDep) => {
          return {
            id: dsDep,
          };
        }
      ),
    };
  });

  return cleanDeps;
}

// Front-load Needed Data to Build a Pipeline

const ETLWorkflowFramework = ({ workflowData }) => {
  // If There is a workflowID found, get the Workflow, also get the state dataSources

  const [{ errors: allSourceErrors, loading: isLoading, data: sources }] =
    useApi(getAllDataSources);
  //for some reason tagFilter is required on this query... why.
  const [
    {
      errors: allTransformationErrors,
      loading: isLoadingTransformations,
      data: transformations,
    },
  ] = useApi(getAllServicerTransferFeeds, {
    tagFilter: {
      tagIds: [],
      exactMatch: false,
    },
    where: {
      enabled: { eq: true },
    },
  });

  const sortedDataSources = sources?.availableWorkflowDataSources?.length
    ? sortByAlpha(sources?.availableWorkflowDataSources, "name")
    : [];

  const sortedTransformations = transformations?.allServicerTransferFeeds?.edges
    ?.length
    ? sortByAlpha(
        transformations?.allServicerTransferFeeds?.edges.map((sf) => sf.node),
        "name"
      )
    : [];

  const stateSources =
    sortedDataSources?.map((source) => {
      return {
        label: source?.name,
        value: source?.id,
        type: "source",
      };
    }) ?? [];

  const stateTransformations =
    sortedTransformations?.map((transfer) => {
      return {
        label: transfer?.name,
        value: transfer?.id,
        type: "transfer",
      };
    }) ?? [];

  if (allSourceErrors) return <ErrorMessages errors={allSourceErrors} />;
  if (isLoading) return <SplashLoader text={"Loading Data Sources"} />;

  return (
    <ETLWorkFlowForm
      workflowData={workflowData}
      stateSources={stateSources}
      stateTransformations={stateTransformations}
    />
  );
};

const ETLWorkFlowForm = ({
  workflowData,
  stateSources,
  stateTransformations,
}) => {
  const workflowDate = workflowData
    ? new Date(workflowData?.alertTriggerTimestamp)
    : null;

  //initialize the reducer state
  const initialState = {
    showChangeThreshold: false,
    addNewPipeline: false,
    // etlProviderInstanceId: workflowData?.etlProviderInstanceId
    //   ? providers.find((p) => p.value === workflowData?.etlProviderInstanceId)
    //   : null,
    workflowName: workflowData?.name ?? "",
    tagInstances:
      workflowData?.tagInstances?.map((ti) => {
        const createTagOption = {
          label: ti?.tag?.name,
          value: ti?.tagId,
          ...ti,
        };
        return createTagOption;
      }) ?? null,
    zone: getZone,
    hour: workflowData
      ? hourOptions.find(
          (o) => o.value === format(workflowDate, "h")?.toString()
        )
      : { value: "1", label: "1" },
    min: workflowData
      ? minuteOptions.find(
          (j) => j.value === workflowData?.alertTriggerTime.minutes.toString()
        )
      : { value: "0", label: ":00" },
    isAm: workflowData
      ? format(workflowDate, "aaaa")?.toString() === "a.m."
        ? { value: true, label: "AM" }
        : { value: false, label: "PM" }
      : { value: false, label: "PM" },
    dependencies: workflowData
      ? buildPipelines(
          workflowData?.dependencies,
          stateSources,
          stateTransformations
        )
      : [
          {
            name: "",
            dataSourceDependencies: [],
            pipelineDependencies: [],
            transformationDependencies: [],
            isTermination: true,
            uid: uuid(),
          },
        ],
  };

  const [state, dispatch] = useReducer(reducer, initialState);
  const [success, setShowSuccess] = useState(false);

  const [{ errors: createErrors, data: createData }, create] = useApi();

  // start listening to process for create if success
  useEffect(() => {
    if (createData && !createErrors?.length) {
      setShowSuccess(true);
    } else if (createErrors?.length) {
      setShowSuccess(false);
    }
  }, [createData, createErrors]);

  let history = useHistory();

  const SaveForm = () => {
    //update workflow vs save new
    if (workflowData) {
      const submitObject = {
        etlProviderInstanceId: state?.etlProviderInstanceId?.value,
        name: state?.workflowName,
        alertTriggerTime: {
          hour: Number(state?.hour?.value),
          minutes: Number(state?.min?.value),
          isAM: state?.isAm?.value,
          timeZone: state?.zone,
        },
        dependencies: flattenDepObjects(state?.dependencies),
      };

      create({ query: updateWorkflow, variables: { workflow: submitObject } });
    } else {
      const submitObject = {
        etlProviderInstanceId: state?.etlProviderInstanceId?.value,
        name: state?.workflowName,
        alertTriggerTime: {
          hour: Number(state?.hour?.value),
          minutes: Number(state?.min?.value),
          isAM: state?.isAm?.value,
          timeZone: state?.zone,
        },
        dependencies: flattenDepObjects(state?.dependencies),
        tagInstanceInputs: state?.tagInstanceInputs,
      };

      create({ query: createWorkflow, variables: { workflow: submitObject } });
    }
  };
  let params = useParams();
  const workflowId = params?.workflowId;
  return (
    <div>
      {success ? (
        <ShowSuccessModal
          workflowData={workflowData}
          setShowSuccess={setShowSuccess}
          dispatch={dispatch}
        />
      ) : null}

      {/* {!state.etlProviderInstanceId ? (
        <Modal
          title={"Create Workflow"}
          hide={() => history.push("/workflows")}
        >
          <EntryPoint
            dispatch={dispatch}
            providers={providers}
            browseRequest={browseRequest}
          />
        </Modal>
      ) : null} */}

      {state.showChangeThreshold ? (
        <Modal
          title={`Change Threshold`}
          hide={() =>
            dispatch({
              type: "TOGGLE_CHANGE_DATASOURCE_THRESHOLD",
            })
          }
        >
          <h6>{state?.showChangeThreshold?.dataSource?.label}</h6>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <div style={{ flex: 1 }}>
              <UpdateThreshold
                dispatch={dispatch}
                data={state?.showChangeThreshold}
              />
            </div>
          </div>
        </Modal>
      ) : null}

      <>
        <WorkflowDetails>
          <div style={{ flex: 1, marginRight: "1rem" }}>
            {workflowData ? (
              state.workflowName
            ) : (
              <FormControl>
                <StyledInput
                  type="text"
                  name="workflowName"
                  label="Name"
                  required={true}
                  value={state.workflowName}
                  placeholder={`Name`}
                  onChange={(e) =>
                    dispatch({
                      type: "UPDATE_FIELD",
                      payload: {
                        field: "workflowName",
                        value: e.target.value,
                      },
                    })
                  }
                />
              </FormControl>
            )}
          </div>

          <div
            style={{ flex: 1, flexDirection: "column", marginRight: "1rem" }}
          >
            <>
              {workflowData?.alertTriggerTimestamp ? (
                <div style={{ marginBottom: ".5rem" }}>
                  <b>Current Alert Time: </b>
                </div>
              ) : null}

              <div style={{ display: "flex", alignItems: "center" }}>
                <div
                  style={{
                    width: "100px",
                    marginRight: "1rem",
                    display: "inline-block",
                  }}
                >
                  <StyledSelect
                    className={`react-select-container`}
                    classNamePrefix={`react-select`}
                    name={`hour`}
                    id={`hour`}
                    inputId={`hourSelect-input`}
                    instanceId={`hourSelect-instance`}
                    label="Hours"
                    options={hourOptions}
                    placeholder={`Select Hours`}
                    menuPortalTarget={document.body}
                    value={state.hour}
                    menuPlacement="auto"
                    onChange={(e) =>
                      dispatch({
                        type: "UPDATE_FIELD",
                        payload: { field: "hour", value: e },
                      })
                    }
                    styles={{
                      control: (provided) => ({
                        ...provided,
                        width: "100px",
                      }),
                    }}
                  />
                </div>
                <div
                  style={{
                    width: "110px",
                    marginRight: "1rem",
                    display: "inline-block",
                  }}
                >
                  <StyledSelect
                    className={`react-select-container`}
                    classNamePrefix={`react-select`}
                    name={`min`}
                    id={`min`}
                    inputId={`minSelect-input`}
                    instanceId={`minSelect-instance`}
                    label="Minutes"
                    options={minuteOptions}
                    placeholder={`Select Minutes`}
                    menuPortalTarget={document.body}
                    value={state.min}
                    menuPlacement="auto"
                    onChange={(e) =>
                      dispatch({
                        type: "UPDATE_FIELD",
                        payload: { field: "min", value: e },
                      })
                    }
                    styles={{
                      control: (provided) => ({
                        ...provided,
                        width: "110px",
                      }),
                    }}
                  />
                </div>
                <div
                  style={{
                    width: "110px",
                    marginRight: "1rem",
                    display: "inline-block",
                  }}
                >
                  <StyledSelect
                    className={`react-select-container`}
                    classNamePrefix={`react-select`}
                    name={`isAm`}
                    id={`isAm`}
                    inputId={`isAmSelect-input`}
                    instanceId={`isAmSelect-instance`}
                    label="AM/PM"
                    options={ampmOptions}
                    menuPortalTarget={document.body}
                    placeholder={`Select AM/PM`}
                    value={state.isAm}
                    menuPlacement="auto"
                    onChange={(e) =>
                      dispatch({
                        type: "UPDATE_FIELD",
                        payload: { field: "isAm", value: e },
                      })
                    }
                    styles={{
                      control: (provided) => ({
                        ...provided,
                        width: "110px",
                      }),
                    }}
                  />
                </div>
                <div
                  style={{
                    display: "inline-block",
                  }}
                >
                  {Intl.DateTimeFormat().resolvedOptions().timeZone}
                </div>
              </div>
            </>
          </div>

          <div
            style={{
              flex: 1,
              marginLeft: "auto",
              textAlign: "right",
            }}
          >
            <Secondary
              type="button"
              list={true}
              onClick={() => history.push("/workflows")}
            >
              Cancel
            </Secondary>

            <Button
              type="button"
              disabled= {!state.workflowName}
              onClick={() => {
                SaveForm();
              }}
            >
              Save
            </Button>
          </div>
        </WorkflowDetails>

        {workflowData ? (
          <div style={{ marginBottom: "1rem" }}>
            <UpdateTags remoteObjectId={workflowId} tagType={"WORKFLOW"} />
          </div>
        ) : (
          <div style={{ marginBottom: "1rem" }}>
            <h3>Tags</h3>
            <p>Select the appropriate tags below.</p>
            <SelectTags
              updateTags={dispatch}
              tagType={"WORKFLOW"}
              dispatchType={"SET_TAGS"}
            />
          </div>
        )}

        {createErrors?.length ? <ErrorMessages errors={createErrors} /> : null}

        <div style={{ overflowX: "auto" }}>
          <RenderSegment
            key={"initial"}
            segment={
              state.dependencies.find((segment) => segment.isTermination) ??
              null
            }
            dependencies={state.dependencies}
            dispatch={dispatch}
            stateSources={stateSources}
            stateTransformations={stateTransformations}
            originNode={true}
          />
        </div>
      </>

      {state?.isInsertingDep && (
        <Modal
          title={`Insert Node`}
          hide={() =>
            dispatch({
              type: "TOGGLE_INSERT",
            })
          }
        >
          <div style={{ display: "flex", justifyContent: "center" }}>
            <div style={{ flex: 1 }}>
              <ActionSelector
                key={state?.isInsertingDep?.segment?.uid}
                dispatch={dispatch}
                segment={state?.isInsertingDep?.segment}
                dependencies={state?.dependencies}
                stateSources={stateSources}
                stateTransformations={stateTransformations}
                isInserting={state?.isInsertingDep}
              />
            </div>
          </div>
        </Modal>
      )}

      {createErrors?.length ? <ErrorMessages errors={createErrors} /> : null}
    </div>
  );
};

export default ETLWorkflowFramework;
