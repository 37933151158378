import React, { useEffect, useState } from "react";
import { v4 as uuid } from "uuid";
import RenderSegment from "./RenderSegment";

import StyledLink from "../../../components/StyledLink";
import { useApi } from "../../../api/useApi";
import { getAllDataSources } from "../../../api/dataSourceQueries";
import { getAllServicerTransferFeeds } from "../../../api/serviceTransferQueries";
import ErrorMessages from "../../../components/Notifications/ErrorMessages";
import SplashLoader from "../../../components/Loaders/SplashLoader";
import { formatMinutes } from "../../../common/formats";

//loop through, give unique id
//replace dependency array ids, with UNique id references
function buildPipelines(dependencies, stateSources, stateTransformations) {
  const uniqueIdArray =
    dependencies.map((dependent) => {
      return { ...dependent, uid: uuid() };
    }) ?? [];

  // Collect all dependency composite keys from all types of dependencies
  const allDependencyKeys = new Set();
  uniqueIdArray.forEach((dep) => {
    dep.pipelineDependencies.forEach((d) =>
      allDependencyKeys.add(`ETL_PIPELINE-${d.id}`)
    );
    dep.dataSourceDependencies.forEach((d) =>
      allDependencyKeys.add(`DATA_SOURCE-${d.id}`)
    );
    dep.transformationDependencies.forEach((d) =>
      allDependencyKeys.add(`TRANSFORMATION-${d.id}`)
    );
  });

  const newDependencies = uniqueIdArray.map((dependent) => {
    // Convert these IDs to the index of the segment so we can manage and assign
    // Swaps the ETL ID for the UUID of the dependent
    const newDependentPipelines = dependent.pipelineDependencies.map((dp) => {
      return {
        id: dp?.id,
        name: dp?.name,
        etlProviderInstanceId: dp?.etlProviderInstanceId,
      };
    });

    const newDependentDataSources = dependent.dataSourceDependencies.map(
      (dd) => {
        const foundDataSource = stateSources.find((ds) => ds.value === dd.id);

        return {
          ...dd,
          label: foundDataSource?.label,
          value: foundDataSource?.value,
          dataQualityThreshold: foundDataSource?.dataQualityThreshold,
        };
      }
    );

    const newDependentTransformations =
      dependent.transformationDependencies.map((dd) => {
        const foundTransformation = stateTransformations?.find(
          (ds) => ds.value === dd?.node?.id
        );

        return {
          ...dd,
          label: foundTransformation?.label,
          value: foundTransformation?.value,
        };
      });

    // Determine if the current dependent is a termination node
    const dependentKey = `${dependent.type}-${dependent.id}`;
    const isTermination = !allDependencyKeys.has(dependentKey);

    return {
      ...dependent,
      // Keep name as a string
      name: dependent?.name,
      pipelineDependencies: newDependentPipelines,
      dataSourceDependencies: newDependentDataSources,
      transformationDependencies: newDependentTransformations,
      isTermination: isTermination,
      ...(dependent?.type === "DATA_SOURCE" && {
        dataQualityThreshold: Number(dependent?.dataSourceDataQualityThreshold),
      }),
    };
  });

  return newDependencies;
}

const WorkflowView = ({ data, workflowId }) => {
  const [{ errors, loading: isLoading, data: stateSources }] =
    useApi(getAllDataSources);
  const [builtDeps, setBuildDeps] = useState([]);
  //for some reason tagFilter is required on this query... why.
  const [
    {
      errors: allTransformationErrors,
      loading: isLoadingTransformations,
      data: stateTransformations,
    },
  ] = useApi(getAllServicerTransferFeeds, {
    tagFilter: {
      tagIds: [],
      exactMatch: false,
    },
    where: {
      enabled: { eq: true },
    },
  });

  useEffect(() => {
    if (
      data?.dependencies?.length &&
      stateSources &&
      stateTransformations?.allServicerTransferFeeds?.edges
    ) {
      const sources =
        stateSources?.availableWorkflowDataSources?.map((source) => {
          return {
            label: source?.name,
            value: source?.id,
            type: "source",
          };
        }) ?? [];

      setBuildDeps(
        buildPipelines(
          data?.dependencies,
          sources,
          stateTransformations?.allServicerTransferFeeds?.edges
        )
      );
    }
  }, [data?.dependencies, stateSources, stateTransformations]);

  if (errors) return <ErrorMessages errors={errors} />;
  if (allTransformationErrors)
    return <ErrorMessages errors={allTransformationErrors} />;
  if (isLoading || isLoadingTransformations)
    return <SplashLoader text={"Loading Entities"} />;

  return (
    <div>
      <>
        {/* <WorkflowDetails> */}

        <div
          style={{
            flex: 1,
            marginBottom: "1rem",
            marginLeft: "1rem",
            marginRight: "1rem",
          }}
        >
          <>
            <div style={{ fontWeight: "bold", marginBottom: ".5rem" }}>
              Alert Time
            </div>
            <div style={{ display: "flex", alignItems: "center" }}>
              <div
                style={{
                  marginRight: "1rem",
                  display: "flex",
                }}
              >
                {formatMinutes(new Date(data.alertTriggerTimestamp))}
              </div>
              <div style={{ marginLeft: "auto" }}>
                <StyledLink
                  to={`/workflows/${workflowId}/edit`}
                  title="Edit Workflow"
                >
                  Edit
                </StyledLink>
              </div>
            </div>
          </>
        </div>

        {/* </WorkflowDetails> */}
        <div style={{ overflowX: "auto" }}>
          <RenderSegment
            key={"initial"}
            segment={builtDeps.find((segment) => segment.isTermination) ?? null}
            dependencies={builtDeps}
            stateSources={
              stateSources?.availableWorkflowDataSources?.map((source) => {
                return {
                  label: source?.name,
                  value: source?.id,
                  type: "source",
                };
              }) ?? []
            }
            stateTransformations={stateTransformations}
          />
        </div>
      </>
    </div>
  );
};

export default WorkflowView;
